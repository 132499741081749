import { Analytics } from '@vercel/analytics/react';
import dayjs from 'dayjs';
import 'dayjs/locale/es-mx';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useReportWebVitals } from 'next/web-vitals';
import React, { useEffect } from 'react';
import { SWRConfig } from 'swr';

import { ModalProvider } from '~components/ModalProvider';
import AuthWrapper from '~components/common/AuthWrapper';
import { getFromAPI } from '~utils/api/common';
import * as gtag from '~utils/gtag';
import usePurchase from '~utils/hooks/usePurchase';

import { SuccessLoginResponse, User } from '~types';

import '../styles/globals.css';

dayjs.locale('es-mx');
declare const window: any;
const Progress = dynamic(() => import('nextjs-progressbar'), { ssr: false }) as any;

interface PageProps {
  authUser?: User;
  fallback?: Record<string, any>;
  authData: SuccessLoginResponse;
}

if (!process.browser) React.useLayoutEffect = React.useEffect;

function MyApp({ Component, pageProps }: AppProps<PageProps>) {
  const { asPath, events } = useRouter();
  const { resetPurchase } = usePurchase();
  useEffect(() => {
    if (!asPath.includes('comprar-boletos')) {
      resetPurchase();
    }
  }, [asPath]);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      gtag.pageView(url);
    };
    events.on('routeChangeComplete', handleRouteChange);
    return () => {
      events.off('routeChangeComplete', handleRouteChange);
    };
  }, [events]);

  useReportWebVitals((metric) => {
    // Use `window.gtag` if you initialized Google Analytics as this example:
    // https://github.com/vercel/next.js/blob/canary/examples/with-google-analytics/pages/_app.js
    window.gtag('event', metric.name, {
      value: Math.round(metric.name === 'CLS' ? metric.value * 1000 : metric.value), // values must be integers
      event_label: metric.id, // id unique to current page load
      non_interaction: true, // avoids affecting bounce rate.
    });
  });

  return (
    <SWRConfig value={{ fetcher: getFromAPI, fallback: pageProps.fallback || {} }}>
      <ModalProvider>
        <AuthWrapper authData={pageProps.authData}>
          {({ user }) => {
            // @ts-ignore
            return <Component authUser={user} {...pageProps} />;
          }}
        </AuthWrapper>
      </ModalProvider>
      <Script id="makeAuth" strategy="afterInteractive">
        {`typeof window.__resumeSession === 'function' && window.__resumeSession(true)`}
      </Script>
      <Progress
        color="#FECC00"
        startPosition={0.3}
        stopDelayMs={200}
        height={3}
        showOnShallow={true}
        options={{ easing: 'ease', speed: 500 }}
      />
      {process.env.NEXT_PUBLIC_VERCEL_ANALYTICS === 'true' && <Analytics />}
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
      />
      <Script
        id="init-gtag"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
    </SWRConfig>
  );
}

export default MyApp;
