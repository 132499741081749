import useSWR, { useSWRConfig } from 'swr';

const useGlobalState = <Data>(
  key: string,
  fallbackData?: Data,
): [Data | undefined, (data: Data) => void] => {
  const { cache } = useSWRConfig();
  const fetcher = () => new Promise<Data>((resolve) => resolve(cache?.get(key)?.data as Data));
  const { data, mutate } = useSWR<Data>(key, {
    fetcher,
    revalidateOnFocus: false,
    revalidateOnMount: false,
    revalidateOnReconnect: false,
    fallbackData,
  });

  const setData = (newData: Data) => {
    mutate(newData, false);
  };

  return [data, setData];
};

export default useGlobalState;
