import Router from 'next/router';
import { useEffect } from 'react';
import useSWR from 'swr';

import useGlobalState from '~utils/hooks/useGlobalState';

import { SuccessLoginResponse, User } from '~types';

declare const window: any;

export function useAuthUser({
  redirectTo,
  redirectIfFound = false,
  fallbackData,
}: {
  redirectTo?: string;
  redirectIfFound?: boolean;
  fallbackData?: SuccessLoginResponse;
} = {}) {
  const [ready, setReady] = useGlobalState('ready', false);
  const [, setSessionResumed] = useGlobalState('sessionResumed', false);
  const [globalUser, setUser] = useGlobalState<User | boolean>('user');
  const { data, error } = useSWR<SuccessLoginResponse>(ready ? `/users/me` : null, {
    fallbackData,
  });

  const user = data?.user;
  const finished = Boolean(data);
  const hasUser = Boolean(user);

  const resumeSession = () => {
    setReady(true);
  };

  useEffect(() => {
    if (user) {
      setUser(user);
    }
    if (finished) {
      setSessionResumed(true);
    }
  }, [user, finished]);

  useEffect(() => {
    if (!hasUser && globalUser) setUser(false);
    if (!redirectTo || !finished) return;
    if (
      // If redirectTo is set, redirect if the user was not found.
      (redirectTo && !redirectIfFound && !hasUser) ||
      // If redirectIfFound is also set, redirect if the user was found
      (redirectIfFound && hasUser)
    ) {
      Router.push(redirectTo);
    }
  }, [redirectTo, redirectIfFound, finished, hasUser]);

  if (typeof window !== 'undefined') {
    window.__resumeSession = resumeSession;
  }

  return error ? null : user;
}

export default useAuthUser;
