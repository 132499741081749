import deepmerge from 'deepmerge';
import { useMemo } from 'react';

import { getFromAPI } from '~utils/api/common';

import { PickedSeat, PickedTicket, Purchase } from '~types';

import useGlobalState from './useGlobalState';
import useLocalStorage from './useLocalStorage';

const INITIAL_STATE: Purchase = {
  tickets: [],
  seats: [],
};

const usePurchase = () => {
  const [purchase, setPurchase] = useLocalStorage<Purchase>('purchase', INITIAL_STATE);
  const [bookingFee, setBookingFee] = useGlobalState('bookingFee', 0);
  const seats = purchase?.seats || [];
  const tickets = useMemo(() => purchase?.tickets || [], [purchase]);

  const total = useMemo(() => {
    if (tickets.length > 0) {
      return (
        tickets.reduce((accum, item) => {
          return item.price + accum;
        }, 0) +
        (bookingFee || 0) * tickets.length
      );
    }

    return 0;
  }, [tickets, bookingFee]);

  const addSeat = (seat: PickedSeat, ticket: PickedTicket) => {
    const limit = Number(process.env.NEXT_PUBLIC_LIMIT_TICKETS);
    if ((purchase?.tickets?.length || 0) < limit) {
      updatePurchase({ seats: [seat], tickets: [ticket] });
    } else {
      import('~components/ui/Notification')
        .then((mod) => mod.default)
        .then((Notification) => {
          Notification.error({
            title: `No es posible seleccionar mas de ${limit} asientos.`,
            message: 'Si quieres eliminar un asiento, vuelve a hacer click sobre el.',
            placement: 'top',
          });
        });
    }
  };

  const removeSeat = (seatId: string) => {
    const seatsLeft = seats.filter((s) => s.id !== seatId);
    const ticketsLeft = tickets.filter((t) => t.id !== seatId);

    setPurchase({
      ...(purchase || {}),
      seats: seatsLeft,
      tickets: ticketsLeft,
    });
  };

  const updateTicketSeat = (ticket: PickedTicket) => {
    const newTickets = tickets.map((t) => {
      if (t.id === ticket.id) {
        return ticket;
      }

      return t;
    });

    setPurchase({ seats, tickets: newTickets });
  };

  const resetPurchase = () => {
    if (purchase?.transIdTemp && purchase?.CinemaId) {
      console.log('cancel transaction');
      const { transIdTemp, CinemaId } = purchase;
      getFromAPI(`/buys/cancel?transIdTemp=${transIdTemp}&CinemaId=${CinemaId}`)
        .then(() => {})
        .catch(() => {})
        .finally(() => {
          setPurchase(INITIAL_STATE);
        });
    } else {
      setPurchase(INITIAL_STATE);
    }
  };

  const setRecord = (record: Purchase['record']) => {
    if (purchase && record) {
      updatePurchase({ ...purchase, record });
    }
  };

  const updatePurchase = (data: Partial<Purchase>) => {
    const updatedPurchase = deepmerge<Purchase>(purchase ?? {}, data);
    if (updatedPurchase?.bookingFee) setBookingFee(updatedPurchase.bookingFee);
    setPurchase(updatedPurchase);
  };

  return {
    ...purchase,
    bookingFee,
    total,
    tickets,
    addSeat,
    removeSeat,
    resetPurchase,
    updateTicketSeat,
    setRecord,
    updatePurchase,
    setPurchase,
  };
};

export default usePurchase;
