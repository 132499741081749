import { PropsWithChildren, createContext, useState } from 'react';

type ModalType = 'login' | 'signup' | null;
type ContextValue = {
  modal: ModalType;
  data: Record<string, any>;
  setModal: (modal: ModalType, data?: Record<string, any>) => void;
};

const ModalContext = createContext<ContextValue>({
  modal: null,
  data: {},
  setModal: () => {},
});

const ModalProvider = (props: PropsWithChildren) => {
  const [modal, setModal] = useState<ModalType>(null);
  const [data, setData] = useState<Record<string, any>>({});
  const setModalWithData: ContextValue['setModal'] = (modalType, _data) => {
    setModal(modalType);
    _data && setData(_data);
  };
  return (
    <ModalContext.Provider
      value={{
        modal,
        data,
        setModal: setModalWithData,
      }}
    >
      {props.children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };
