import { ReactNode, useEffect } from 'react';

import useAuthUser from '~utils/hooks/useAuthUser';
import useGlobalState from '~utils/hooks/useGlobalState';

import { SuccessLoginResponse, User } from '~types';

interface Props {
  fallback?: ReactNode;
  children: (data: { user?: User | null }) => JSX.Element;
  authData?: SuccessLoginResponse;
}

const AuthWrapper = ({ children, fallback, authData }: Props): JSX.Element => {
  const [, setUser] = useGlobalState<User>('user', authData?.user);
  const user = useAuthUser({ fallbackData: authData });

  useEffect(() => {
    if (user) {
      setUser(user);
    }
  }, [user]);

  if (!user && !!fallback) return fallback as JSX.Element;

  return children({ user });
};

export default AuthWrapper;
